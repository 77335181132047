import React, { SFC } from 'react';
import { notification, Divider, Input,  Button , Upload, Icon, message, List, Modal, Breadcrumb, Dropdown, Menu, Avatar } from 'antd';
import moment from 'moment';
import _ from 'lodash';

// import SendEmailForm from './SendEmailPrompt';

interface Props {
    module?: any;
    exclude?: any;
    fileColumnsToExclude?: any;
    folderColumnsToExclude?: any;
    hideHeader?: boolean;
    match?: any;
    // filename: string;
}

interface State {
    files: any[]; 
    folders: any[];
    uploading: boolean;
    filesToUpload: any[];
    parent: any;
    parentId: number;
    directory: any;
    breadcrumbIDs: any[];
    readonly: boolean;
    visibleEmailLinkEditor: boolean;
    visibleAddModal: boolean;
    visibleEditModal: boolean;
    link: string;
    folderNewName: string;
}

const FileFolderDetails: SFC<{item: any}> = (props) => (
  <div style={{clear: "both"}}>
    {props.item.owner && props.item.owner.id ? (
        <span>{props.item.owner.first_name + ' ' + props.item.owner.last_name} | </span>
    ):null}
    {props.item.uploaded_at && moment(props.item.uploaded_at).isValid() ? (
        <span>{moment(props.item.uploaded_at).fromNow()}</span>
    ):null}
    {props.item.file_type ? (
        <div style={{float:"right"}}>{props.item.file_type}</div>
    ):null}
  </div>
);

export default class FileUploader extends React.Component<Props,State> {

    constructor(Props) {
        super(Props);
        this.state = {
            files: [],
            folders: [],
            uploading: false,
            filesToUpload: [],
            parent: {},
            parentId: 0,
            directory: "",
            breadcrumbIDs: [],
            readonly: false,
            visibleEmailLinkEditor: false,
            visibleAddModal: false,
            visibleEditModal: false,
            link: "",
            folderNewName: "",
        }
    }

    componentDidMount() {
        this.getFiles();
    }

    componentWillReceiveProps(nextProps) {
            if (this.props.module != nextProps.module) {
                this.getFiles();
            }     
    }

    getFiles = () => { // And folders of the CURRENT directory
        var module = this.props.module;
        var page = 0;
        var url = '/api/files/' + module + '/list/' + this.state.parentId
        + "/" + page;

        fetch(url, {
            method: 'get',
            credentials: 'same-origin', 
        }).then(res => {
            return res.json();
        }).then(data => {
            var folders = data.folders;
            if(this.props.exclude && this.props.exclude.length > 0) {
                folders = _.filter(data.folders, f => {
                    if(f.name.trim().toLowerCase() !== this.props.exclude.trim().toLowerCase()) {
                        return f;
                    }
                });
            }
            if(data.files) {
                this.setState({
                    files: data.files,
                    folders: folders,
                    parent: data.parent,
                    directory: data.directory,
                    breadcrumbIDs: data.breadcrumbIDs,
                    readonly: data.readonly
                });
            } else {
                notification.open({
                    message: "Insufficient permissions to view this module",
                    description: ""
                })
            }
        }).catch(error =>
            notification.open({
                message: error.message,
                description: ""
            })
        )
    }

    downloadFile = (data: any): void => {
        var module = this.props.module;
        var url = "/api/files/" + module + "/download/" + data.id;
        window.open(url, "_blank");
    }

    handleDeleteFile = (row: any): any => {
        if (!confirm("Delete the file '"+row.display_name+"'?")) {
            return false;
        }
        var folder_id = this.state.parentId;

        var module = this.props.module;
        var url = '/api/files/' + module + '/' + folder_id + "/"+ row.id;

        fetch(url, {
            method: 'delete',
            credentials: 'same-origin',
        }).then(res => {
            return res.json();
        }).then(data => {
        if(data.success) {
            notification.open({
                message: data.message,
                description: "",
            })
            var files = _.remove(this.state.files, f => {
                return f.id !== row.id;
            });
            this.setState({
                files: files,
            });
        } else {
            notification.open({
                message: data.message,
                description: "",
            });
        }
        }).catch(error => {
        notification.open({
            message: error.message,
            description: "",
        });
        })
    }

    handleDeleteFolder = (row: any): any => {
        if (!confirm("Delete the folder '"+row.name+"'?")) {
            return false;
        }

        var module = this.props.module;
        var url = '/api/files/' + module + '/' + row.id;

        fetch(url, {
            method: 'delete',
            credentials: 'same-origin',
        }).then(res => {
            return res.json();
        }).then(data => {
            if(data.success) {
            notification.open({
                message: data.message,
                description: "",
            })
            var folders = _.remove(this.state.folders, f => {
                return f.id !== row.id;
            });
            this.setState({
                folders: folders,
            });
            } else {
            notification.open({
                message: data.message,
                description: "",
            });
            }
        }).catch(error => {
            notification.open({
                message: error.message,
                description: "",
            });
        });
    }

    openEmailLinkEditor = (fileRow: any): void => {
        this.setState({
            link: "/api/files/"+fileRow.module+"/download/"+fileRow.id,
            visibleEmailLinkEditor: true
        });
    }

    closeEmailLinkEditor = (): void => {
        // this.setState({
        //     visibleEmailLinkEditor: false
        // });
    }

    showUploadButton = () => {
        var module =  this.props.module;
        const props = {
            name: 'file',
            action: '/api/files/' + module + '/upload/' + this.state.parentId,
            withCredentials: true,
            headers:{
                authorization: 'authorization-text',
            },
            onSuccess: (res:any) => {
                message.success(res.msg);
                this.getFiles();
            },
            onChange: (info: any) => {
                console.log(info);
                if(info.file.status === 'done') {
                    message.success(`${info.file.name} file uploaded successfully`);
                    this.getFiles();
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed`);
                }
            }
        }
        return (
            <Upload {...props} fileList={[]} multiple={true}>
            <div role="button"><Icon type="upload" /> Upload</div>
            </Upload>
        );
    }

    showAddFolderButton = () => {
        return (
        <div role="button" onClick={this.createFolder}>
            <Icon type="folder"/> Add Folder
        </div>
        );
    }

    onAddModalOk = () => {
        var current_folder_id: number = this.state.parentId;
        var folder: string = this.state.folderNewName;

        var data = {
            name: folder,
        }

        var module = this.props.module;
        var url = '/api/files/' + module + '/' + current_folder_id

        fetch(url, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        }).then(res => {
            return res.json();
        }).then(data => {
            var folders = this.state.folders;
            folders.push(data.folder);
            this.setState({
                folders: folders,
                visibleAddModal: false,
            });
            notification.open({
                message: data.message,
                description: '',
            });
        }).catch(error => {
            notification.open({
                message: error.message,
                description: '',
            });
        });
    }

    onRenameModalOk = (e: any) => {
        var current_folder_id: number = this.state.parentId;
        var folder: string = this.state.folderNewName;

        var module = this.props.module;
        var url = '/api/files/' + module + '/' + current_folder_id

        var data = {
            name: folder,
        }

        fetch(url, {
            method: 'PUT',
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        }).then(res => {
            return res.json();
        }).then(data => {
            var folders = this.state.folders;
            for(var i:number = 0; i<folders.length; i++) {
                if(folders[i].id === data.folder) {
                    folders[i].name = folder;
                }
            }

            this.setState({
                folders: folders,
                visibleEditModal: false,
            });

            notification.open({
                message: data.message,
                description: '',
            });
        }).catch(error => {
            notification.open({
                message: error.message,
                description: '',
            });
        });
    }

    onModalCancel = () => {
        this.setState({
            visibleAddModal: false,
            visibleEditModal: false,
        });
    }

    createFolder = () => {
        this.setState({
            visibleAddModal: true,
        });
    }

    editFolder = () => {
        this.setState({
            visibleEditModal: true,
        })
    }

    displayFileContextMenu = (row: any): any => {
        let ctxMenu = (
        <Menu>
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" onClick={this.downloadFile.bind(this, row)}><Icon type="download" /> Download</a>
            </Menu.Item>
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" onClick={this.handleDeleteFile.bind(this, row)}> <Icon type="delete" /> Delete</a>
            </Menu.Item>
            {/* <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" onClick={this.openEmailLinkEditor.bind(this, row)}> <Icon type="mail" /> Email Link</a>
            </Menu.Item> */}
        </Menu>
        );
        if (this.state.readonly) {
        ctxMenu = (
            <Menu>
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" onClick={this.downloadFile.bind(this, row)}><Icon type="download" /> Download</a>
            </Menu.Item>
        </Menu>
        );
        }
        return [
        <Dropdown overlay={ctxMenu}>
            <Button type="dashed" shape="circle-outline" icon="down" />
        </Dropdown>
        ];
    }

    displayFolderContextMenu = (row: any): any => {
        const ctxMenu = (
        <Menu>
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" onClick={this.handleEditFolder.bind(this, row)}> <Icon type="edit" /> Rename</a>
            </Menu.Item>
            {!this.state.readonly ? (
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" onClick={this.handleDeleteFolder.bind(this, row)}> <Icon type="delete" /> Delete</a>
            </Menu.Item>
            ) : ""}
        </Menu>
        );
        const ret = !this.state.readonly ? (
        [
            <Dropdown overlay={ctxMenu}>
            <Button type="dashed" shape="circle-outline" icon="down" />
            </Dropdown>
        ]
        ) : []
        return ret
    }

    displayFileCards = (rows: any) => {
        return (
            <List
            itemLayout="horizontal"
            split={true}
            dataSource={rows}
            renderItem={(item: any) => (
                <List.Item actions={this.displayFileContextMenu(item)}>
                <List.Item.Meta
                    style={{textAlign: "left"}}
                    avatar={<Avatar shape="circle" icon='file' size="large" />}   
                    title={item.display_name} 
                    description={<FileFolderDetails item={item}/>} />
                </List.Item>
            )} /> 
        
        );
    }

    displayFolderCards = (rows: any) => {
        return(
            <List
            itemLayout="horizontal"
            split={true}
            dataSource={rows}
            renderItem={(item: any) => (
                <List.Item 
                style={{textAlign: "left"}}
                actions={this.displayFolderContextMenu(item)}>
                    <div style={{cursor:"pointer"}} onClick={this.navigateToFolder.bind(this, item.id)}>
                    <List.Item.Meta
                        avatar={<Avatar shape="circle" icon="folder" size="large" />} 
                        description={<FileFolderDetails item={item}/>}
                        title={item.name} />
                    </div>
                </List.Item>     
            )} /> 

        );
    }

    handleAddFolder = (e: any) => {
        this.setState({
            folderNewName: e.target.value,
        })
    }

    handleRenameFolder = (e: any) => {
        this.setState({
            folderNewName: e.target.value,
        })
    }

    handleEditFolder = (e: any) => {
        this.setState({
            visibleEditModal: true,
            parentId: e.id,
        });
    }

    navigateToFolder = (row: any) => {
        this.setState({
            parentId: row
        }, this.getFiles);
        // find a way for it to wait for state to be set
    }
    
    handleBreadCrumbClick = (id: number, e: any) => {
        e.preventDefault();
        this.navigateToFolder(id);
    }

    showBreadCrumbs() {
        var breadcrumbIDs = this.state.breadcrumbIDs;
        var directories = this.state.directory.split('/');

        var directories_map = new Map([directories]);

        for(var i:number = 0; i< directories.length; i++) {
            directories_map.set(directories[i], breadcrumbIDs[i]);
        }

        var i: number = 0;

        return(
        <Breadcrumb>   
            {directories.map((d) => {
            return (
                <Breadcrumb.Item href="" 
                rel="noopener noreferrer"
                key={d} 
                onClick={this.handleBreadCrumbClick.bind(this, directories_map.get(d))}>{d}</Breadcrumb.Item>
            );
            })}
        </Breadcrumb>
        );
    }

    getCurrentFolderName = (): string => {
        for(var i: number = 0; i<this.state.folders.length; i++) {
            if(this.state.folders[i].id === this.state.parentId) {
                return this.state.folders[i].name;
            }
        }

        return "";
    }

    render() {
        const menu = (
        <Menu>
            <Menu.Item>{this.showUploadButton()}</Menu.Item>
            <Menu.Item>{this.showAddFolderButton()}</Menu.Item>
        </Menu>
        );
        
        return (
        <div>
            <div style={{float:"left"}}>
            {this.showBreadCrumbs()}
            </div>
            <div style={{float:"right"}}>
                <Dropdown overlay={menu}>
                    <Button size="large" type="primary" shape="circle" icon="plus" />
                </Dropdown>
            </div>
            <br /><br />
            <Divider orientation="left" />
            

            {/*<SendEmailForm
                visible={this.state.visibleEmailLinkEditor}
                link={this.state.link}
                close={this.closeEmailLinkEditor}
            />*/}

            <Modal  
                title="Folder Add"
                visible={this.state.visibleAddModal}
                onOk={this.onAddModalOk}
                onCancel={this.onModalCancel}
            >
                <Input
                    placeholder="Folder Name"
                    onChange={this.handleAddFolder.bind(this)}
                    style={{height:30}}
                />
            </Modal>

            <Modal  
                title="Folder Edit"
                visible={this.state.visibleEditModal}
                onOk={this.onRenameModalOk}
                onCancel={this.onModalCancel}
            >
                <Input
                    placeholder="Folder Name"
                    defaultValue={this.getCurrentFolderName()} 
                    onChange={this.handleRenameFolder.bind(this)}
                    style={{height:30}}

                />
            </Modal>

            {this.state.folders.length > 0 ? this.displayFolderCards(this.state.folders):null}
            {this.state.files.length > 0 ? this.displayFileCards(this.state.files):null}

            {this.state.folders.length == 0 && this.state.files.length == 0 ? (
            <span>No Files</span>
            ): null}

        </div>
        );
    }
}

        

        