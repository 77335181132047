import React from 'react';
import { Layout } from 'antd';
import { inject, observer } from 'mobx-react';
const { Header, Footer, Content } = Layout;
import { Switch, Route, withRouter } from 'react-router-dom';
import { Redirect } from 'react-router';
import { SideMenu } from './SideMenu';
import { CustomerScreen, CustomerForms } from 'core/customers';
import { uiStore, UIStore } from '../stores/Store';
import Files from '../screens/FilesScreen';
import Settings from '../screens/SettingsScreen';
import { Avatar, Menu, Dropdown, Icon, Row, Col } from 'antd';

@inject(() => ({
	ui: uiStore as UIStore
}))
@observer
class MainScreen extends React.Component<{ ui?: UIStore }, { id: number; first_name: string; last_name: string }> {
	ui: UIStore;
	constructor(props: any) {
		super(props);
		this.ui = props.ui!;

		this.state = {
			id: 0,
			first_name: '',
			last_name: ''
		};
	}

	public render() {
		const menu = (
			<Menu>
				<Menu.Item onClick={this.ui.logout}>Logout</Menu.Item>
			</Menu>
		);

		return (
			<Layout>
				<SideMenu />

				<Header
					style={{
						background: '#fff',
						padding: 0,
						position: 'fixed',
						left: 0,
						zIndex: 99,
						paddingLeft: !this.ui.isSideMenuCollapsed ? 200 : 85,
						marginBottom: 10,
						width: '100%'
					}}
				>
					<Row>
						<Col span={24}>
							<div style={{ float: 'left', paddingLeft: '25px', paddingTop: '20px' }}>
								<img src="/static/assets/images/logo.png" height="25" />
							</div>
							<div style={{ float: 'right', paddingRight: '25px' }}>
								<Avatar size="large" icon="user" />
								&nbsp;&nbsp;
								<Dropdown overlay={menu}>
									<a className="ant-dropdown-link" href="#">
										{this.ui.loggedInUser.first_name} {this.ui.loggedInUser.last_name}{' '}
										<Icon type="down" />
									</a>
								</Dropdown>
							</div>
							<div style={{ clear: 'both' }} />
						</Col>
					</Row>
				</Header>
				<Layout
					style={{
						marginLeft: !this.ui.isSideMenuCollapsed ? 200 : 85
					}}
				>
					<Content
						style={{
							margin: '85px 16px 0',
							overflow: 'initial'
						}}
					>
						<div
							style={{
								padding: 24,
								background: '#fff'
							}}
						>
							<Switch>
								<Redirect from="/" exact to="/users" />
								<Route exact path="/users" component={CustomerScreen} />
								<Route exact path="/users/:id" component={(props) => <CustomerForms {...props} />} />
								<Route exact path="/users/create/:role" component={CustomerForms} />
								<Route exact path="/files" component={Files} />
								<Route exact path="/settings" component={Settings} />
							</Switch>
						</div>
					</Content>
					<Footer
						style={{
							textAlign: 'right'
						}}
					/>
				</Layout>
			</Layout>
		);
	}
}

export const Main = withRouter(MainScreen);
