import React from 'react';
import FileUploader from 'core/files/FileUploader';

interface Props {}

interface State {}

export default class Files extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
	}

	render() {
		return <FileUploader module="Administration" />;
	}
}
