import React from 'react';
import ReactDOM from 'react-dom';
//import {AppContainer} from "react-hot-loader";
import App from './components/App';
import './style.less';
import { HashRouter } from 'react-router-dom';
import { configure } from 'mobx';

configure({
	enforceActions: true
});

const rootDOMElement = document.getElementById('app');

const render = (AppComponent: any) => {
	ReactDOM.render(
		<HashRouter>
			<AppComponent />
		</HashRouter>,
		rootDOMElement
	);
};

render(App);

if ((module as any).hot) {
	(module as any).hot.accept('./components/App', () => {
		const NextApp = require('./components/App').default; // tslint:disable-line no-var-requires
		render(NextApp);
	});
}
