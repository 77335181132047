import React, { SFC } from 'react';
import { FormComponentProps } from 'antd/lib/form/Form';
import { observer } from 'mobx-react';
import { Form, Input, Button, Row, Col, Select, InputNumber, Divider } from 'antd';
import { RequiredFile, IFileExpiry } from '../stores/models/RequiredFile';
import { SettingsStore } from '../stores/SettingsStore';

const FormItem = Form.Item;
const Option = Select.Option;

interface ISettingsForm {
	data: RequiredFile;
	store: SettingsStore;
}

const ValidFor: SFC<{ f?: IFileExpiry }> = (props) => {
	if (props.f && props.f.disabled) {
		return <span style={{ color: '#ccc' }}> Disabled </span>;
	} else {
		return <span style={{ color: '#ccc' }}> Valid for </span>;
	}
};

@observer
class SettingsForm extends React.Component<ISettingsForm & FormComponentProps, {}> {
	public constructor(props: ISettingsForm & FormComponentProps) {
		super(props);
	}

	componentDidMount() {
		this.props.store.registerForm(this.props.data.role, this.props.form);
	}

	handleAddField = (e: any) => {
		this.props.form.validateFields((err, values) => {
			if (!err) {
				var file = {
					id: 0,
					file: this.props.form.getFieldValue('newfile'),
					period: this.props.form.getFieldValue('newperiod'),
					amount: this.props.form.getFieldValue('newamount'),
					sub_role: this.props.form.getFieldValue('newsubrole')
				};
				this.props.store.addNewItem(this.props.data.role, file);
				this.props.form.resetFields([ 'newfile', 'newperiod', 'newamount', 'newsubrole' ]);
			}
		});
	};

	render() {
		const { getFieldDecorator } = this.props.form;
		const { data } = this.props;
		const { store } = this.props;

		const requiredFiles = data.file_expiry.map((f, i) => {
			return (
				<Row key={'new-role-file' + i} gutter={10}>
					<Col offset={0} xs={8} sm={8} md={8} lg={8} xl={8}>
						<FormItem
							style={{
								display: 'none'
							}}
						>
							{getFieldDecorator('id:' + f.id, { initialValue: f.id })(<Input disabled={f.disabled} />)}
						</FormItem>
						<FormItem>
							{getFieldDecorator('file:' + f.id, {
								initialValue: f.file,
								rules: [
									{
										required: true,
										message: 'Please fill in file'
									}
								]
							})(<Input placeholder="File" disabled={f.disabled} />)}
						</FormItem>
					</Col>
					{data.role === 'Shareholder' ? (
						<Col xs={8} sm={8} md={8} lg={8} xl={8} offset={0}>
							<FormItem>
								{getFieldDecorator('subrole:' + f.id, { initialValue: f.sub_role })(
									<Select placeholder="Sub Role" disabled={f.disabled}>
										<Option value="N/A">N/A</Option>
										<Option value="Individuals">Individuals</Option>
										<Option value="Entities">Entities</Option>
										<Option value="Corporate Entities">Corporate Entities</Option>
										<Option value="Corporate Entities Exchange">
											Corporate Listed on A Recognised Exchange
										</Option>
										<Option value="Partnerships or Other Unicorporated Businesses">
											Partnerships or Other Unicorporated Businesses
										</Option>
										<Option value="Trusts">Trusts</Option>
										<Option value="Designated Body Acting as Principal">
											Designated Body Acting as Principal
										</Option>
										<Option value="Designated Body Acting as Nominee">
											Designated Body Acting as Nominee
										</Option>
										<Option value="AIFS">AIFS</Option>
									</Select>
								)}
							</FormItem>
						</Col>
					) : (
						<Col xs={8} sm={8} md={8} lg={8} xl={8} offset={0} />
					)}
					<Col offset={0} xs={2} sm={2} md={2} lg={2} xl={2}>
						<ValidFor f={f} />
					</Col>
					<Col xs={2} sm={2} md={2} lg={2} xl={2}>
						<FormItem>
							{getFieldDecorator('amount:' + f.id, {
								initialValue: f.amount,
								rules: [
									{
										required: true,
										message: 'Enter amount'
									}
								]
							})(<InputNumber min={1} max={32} placeholder="" disabled={f.disabled} />)}
						</FormItem>
					</Col>
					<Col xs={2} sm={2} md={2} lg={2} xl={2}>
						<FormItem>
							{getFieldDecorator('period:' + f.id, {
								initialValue: f.period,
								rules: [
									{
										required: true,
										message: 'Please choose period'
									}
								]
							})(
								<Select placeholder="Period" disabled={f.disabled}>
									<Option value="Days">Days</Option>
									<Option value="Weeks">Weeks</Option>
									<Option value="Months">Months</Option>
									<Option value="Years">Years</Option>
								</Select>
							)}
						</FormItem>
					</Col>

					<Col offset={0} xs={2} sm={2} md={2} lg={2} xl={2}>
						<div style={{ float: 'right' }}>
							<Button
								title={f.disabled ? 'Enable' : 'Disable'}
								type="dashed"
								shape="circle"
								icon={f.disabled ? 'lock' : 'unlock'}
								onClick={store.disableItem.bind(this, f.id)}
							/>
							{/* <Divider type="vertical" />
							<Button
								title="Remove"
								type="dashed"
								shape="circle"
								icon="delete"
								onClick={store.removeItem.bind(this, f.id)}
							/> */}
						</div>
						<div style={{ clear: 'both' }} />
					</Col>
				</Row>
			);
		});

		const newFile = (
			<Row key="role-file-0" gutter={10}>
				<Col offset={0} xs={8} sm={8} md={8} lg={8} xl={8}>
					<FormItem>{getFieldDecorator('newfile', {})(<Input placeholder="File" />)}</FormItem>
				</Col>
				{data.role === 'Shareholder' ? (
					<Col xs={8} sm={8} md={8} lg={8} xl={8} offset={0}>
						<FormItem>
							{getFieldDecorator('newsubrole', {})(
								<Select placeholder="Sub Role">
									<Option value="" />
									<Option value="Individuals">Individuals</Option>
									<Option value="Entities">Entities</Option>
									<Option value="Corporate Entities">Corporate Entities</Option>
									<Option value="Corporate Entities Exchange">
										Corporate Listed on A Recognised Exchange
									</Option>
									<Option value="Partnerships or Other Unicorporated Businesses">
										Partnerships or Other Unicorporated Businesses
									</Option>
									<Option value="Trusts">Trusts</Option>
									<Option value="Designated Body Acting as Principal">
										Designated Body Acting as Principal
									</Option>
									<Option value="Designated Body Acting as Nominee">
										Designated Body Acting as Nominee
									</Option>
									<Option value="AIFS">AIFS</Option>
								</Select>
							)}
						</FormItem>
					</Col>
				) : (
					<Col xs={8} sm={8} md={8} lg={8} xl={8} offset={0} />
				)}
				<Col offset={0} xs={2} sm={2} md={2} lg={2} xl={2}>
					<ValidFor />
				</Col>
				<Col xs={2} sm={2} md={2} lg={2} xl={2}>
					<FormItem>
						{getFieldDecorator('newamount', {})(<InputNumber min={1} max={32} placeholder="" />)}
					</FormItem>
				</Col>
				<Col xs={2} sm={2} md={2} lg={2} xl={2}>
					<FormItem>
						{getFieldDecorator('newperiod', {})(
							<Select placeholder="Period">
								<Option value="Days">Days</Option>
								<Option value="Weeks">Weeks</Option>
								<Option value="Months">Months</Option>
								<Option value="Years">Years</Option>
							</Select>
						)}
					</FormItem>
				</Col>
				<Col offset={0} xs={2} sm={2} md={2} lg={2} xl={2}>
					<div style={{ float: 'right' }}>
						<Button title="Add" type="primary" shape="circle" icon="plus" onClick={this.handleAddField} />
					</div>
					<div style={{ clear: 'both' }} />
				</Col>
			</Row>
		);

		return (
			<div>
				<Form>
					{newFile}
					<Divider orientation="left" style={{ marginTop: 0 }} />
					{requiredFiles}
				</Form>
			</div>
		);
	}
}

const SettingsDetails = Form.create<ISettingsForm>()(SettingsForm);
export default SettingsDetails;
