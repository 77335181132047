import { observable, action } from 'mobx';

export class UIStore {
	@observable public isSideMenuCollapsed: boolean;

	@observable public isDrawerOpen: boolean;

	@observable public drawerPosition: string;

	@observable public drawerNoOverlay: boolean;

	@observable public formNum: number;

	@observable public isLoggedIn: boolean;

	@observable public loggedInUser: any;

	constructor() {
		this.isSideMenuCollapsed = false;
		this.isDrawerOpen = false;
		this.drawerPosition = 'right';
		this.loggedInUser = {
			id: 0,
			first_name: '',
			last_name: ''
		};
		this.drawerNoOverlay = false;
	}

	componentWillMount() {
		this.getCurrentUser();
	}

	getCurrentUser = () => {
		fetch('/api/currentuser', {
			method: 'get',
			credentials: 'same-origin'
		})
			.then((res) => {
				return res.json();
			})
			.then(this.populateCurrentUser);
	};

	@action
	populateCurrentUser = (data: any) => {
		this.loggedInUser = data.user;
	};

	@action
	handleResponse = (res: Response): void => {
		switch (res.status) {
			case 403:
				this.isLoggedIn = false;
				break;
			case 200:
				this.isLoggedIn = true;
				this.getCurrentUser();
				break;
		}
	};

	init(): void {
		fetch('/api/check-is-loggedin', {
			method: 'get',
			credentials: 'same-origin'
		}).then(this.handleResponse);
	}

	auth(username: string, password: string): void {
		fetch('/api/login', {
			method: 'post',
			credentials: 'same-origin',
			headers: {
				'content-type': 'application/json'
			},
			body: JSON.stringify({ username: username, password: password })
		})
			.then((res) => {
				return res.json();
			})
			.then((json) => {
				if (json.success) {
					this.setLoggedStatus(true);
					this.getCurrentUser();
				} else {
					this.setLoggedStatus(false);
				}
			});
	}

	logout(): void {
		fetch('/api/logout', {
			method: 'get',
			credentials: 'same-origin',
			headers: {
				'content-type': 'application/json'
			}
		})
			.then((res) => {
				return res.json();
			})
			.then((json) => {
				if (json.success) {
					document.location.href = '/';
				}
			});
	}

	@action
	setLoggedStatus = (loginStatus: boolean) => {
		this.isLoggedIn = loginStatus;
	};

	@action
	toggleSideMenu = () => {
		this.isSideMenuCollapsed = !this.isSideMenuCollapsed;
	};

	@action
	toggleDrawer = () => {
		this.isDrawerOpen = !this.isDrawerOpen;
	};
}

export const uiStore = new UIStore();
