import * as React from 'react';
import {Form, Icon, Input, Button} from 'antd';
import {uiStore, UIStore} from '../stores/Store';
import {inject} from 'mobx-react';
const FormItem = Form.Item;

interface LoginFormProps {
  form : any;
  store : UIStore;
}

@inject(() => ({store: uiStore as UIStore}))
class LoginForm extends React.Component < LoginFormProps, {} > {

  public store: UIStore;

  constructor(props : LoginFormProps) {
    super(props);
    this.store = uiStore;
  }

  handleSubmit = (e) => {
    const {form} = this.props;
    e.preventDefault();
    form.validateFields((err, values) => {
      this
        .store
        .auth(values.username, values.password);
    });
  };

  render() {
    const {getFieldDecorator} = this.props.form;
    return (
      <div
        style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        marginTop: '15%'
      }}>
        <Form onSubmit={this.handleSubmit} className="login-form">
          <div
            style={{
            textAlign: 'center',
            padding: 20
          }}>
            <img src="/static/assets/images/logo.png" height="25"/>
          </div>
          <FormItem label="Username">
            {getFieldDecorator('username', {
              rules: [
                {
                  required: true
                }
              ]
            })(
              <Input
                prefix={< Icon type = "user" style = {{ color: 'rgba(0,0,0,.25)' }}/>}
                placeholder="Username"/>
            )}
          </FormItem>
          <FormItem label="Password">
            {getFieldDecorator('password', {
              rules: [
                {
                  required: true
                }
              ]
            })(
              <Input
                prefix={< Icon type = "lock" style = {{ color: 'rgba(0,0,0,.25)' }}/>}
                type="password"
                placeholder="Password"/>
            )}
          </FormItem>
          <FormItem>
            <Button type="primary" htmlType="submit" className="login-form-button">
              Log in
            </Button>
          </FormItem>
        </Form>
      </div>
    );
  }
}

export default Form.create()(LoginForm);
