import React from 'react';
import { Select } from 'antd';
import FormComponentInterface from './FormComponentInterface';

const Option = Select.Option;

interface Props {
    model: any;
    form: any;
    initialValue: any;
}

interface Store {

}

export default class SelectComponent extends React.Component <Props, Store> implements FormComponentInterface {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return(
            <div>
                {getFieldDecorator(this.props.model.key, {
                    rules: [{required: this.props.model.required, message: this.props.model.message}],
                    initialValue: this.props.initialValue,
                })(
                    <Select 
                        placeholder={this.props.model.placeholder} 
                        showSearch={true}
                        optionFilterProp="children"
                    >
                            {this.props.model.options.map((o: any) => (
                                <Option key={o.key} value={o.value}>{o.value}</Option>
                        ))}
                    </Select>
                )}
            </div>
        )
    }
}