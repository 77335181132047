import React from 'react';
import { Radio } from 'antd';
import FormComponentInterface from './FormComponentInterface';

const RadioGroup = Radio.Group;

interface Props {
    model: any;
    form: any;
    initialValue: any;
}

interface Store {
    value: string;
}

export default class RadioComponent extends React.Component <Props, Store> implements FormComponentInterface {
    constructor(props: Props) {
        super(props);
        var val: string;

        if(this.props.initialValue !== null && this.props.initialValue !== undefined) {
            val = this.props.initialValue;
        } else {
            val = this.props.model.options[0];
        }

        this.state = {
            value: val,
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        return(
            <div>
                {getFieldDecorator(this.props.model.key, {
                    rules: [{required: this.props.model.required, message: this.props.model.message}],
                    initialValue: this.props.initialValue,
                })(
                    <RadioGroup>
                        {this.props.model.options.map((o: any) => (
                            <Radio key={o.key} value={o.value}>{o.value}</Radio>
                        ))}
                    </RadioGroup>
                )}
            </div>
        )
    }
}