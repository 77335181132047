import React, { SFC } from 'react';
import { List, Avatar, Upload, message, Icon, Button, Divider, Modal, notification } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import { IFileExpiry } from '~/fcs-crm/src/stores/models/RequiredFile';

interface Props {
	module?: any;
	requiredfiles: any[];
	externalId: number;
	subRole: string;
}

interface State {
	files: any[];
	parentId: number;
	preview: {
		visible: boolean;
		type: string;
		path: string;
	};
}

const FileFolderDetails: SFC<{ item: any }> = (props) => (
	<div style={{ clear: 'both' }}>
		{props.item.owner && props.item.owner.id ? (
			<span>{props.item.owner.first_name + ' ' + props.item.owner.last_name} | </span>
		) : null}
		{props.item.uploaded_at && moment(props.item.uploaded_at).isValid() ? (
			<span>{moment(props.item.uploaded_at).fromNow()}</span>
		) : null}
		{props.item.file_type ? <div style={{ float: 'right' }}>{props.item.file_type}</div> : null}
	</div>
);

export default class FileList extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			files: [],
			parentId: 0,
			preview: {
				visible: false,
				type: '',
				path: ''
			}
		};
	}

	componentDidMount() {
		this.getFiles();
	}

	handleDeleteFile = (row: any): any => {
		if (!confirm("Delete the file '" + row.display_name + "'?")) {
			return false;
		}
		var folder_id = this.state.parentId;

		var module = this.props.module;
		var url = '/api/files/' + module + '/' + folder_id + '/' + row.id;

		fetch(url, {
			method: 'delete',
			credentials: 'same-origin'
		})
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				if (data.success) {
					notification.open({
						message: data.message,
						description: ''
					});
					var files = _.remove(this.state.files, (f) => {
						return f.id !== row.id;
					});
					this.setState({
						files: files
					});
				} else {
					notification.open({
						message: data.message,
						description: ''
					});
				}
			})
			.catch((error) => {
				notification.open({
					message: error.message,
					description: ''
				});
			});
	};

	getFiles = () => {
		var module = this.props.module;
		var page = 0;
		var url = '/api/files/' + module + '/list/' + this.state.parentId + '/' + page;

		fetch(url, {
			method: 'get',
			credentials: 'same-origin'
		})
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				var files = new Array();
				for (var i: number = 0; i < data.files.length; i++) {
					files.push(data.files[i]);
				}
				this.setState({
					files: files
				});
			});
	};

	showUploadSection = (field: IFileExpiry): any => {
		var files = new Array();
		var found: boolean = false;
		for (var i = 0; i < this.state.files.length; i++) {
			if (this.state.files[i].external_id === field.id) {
				var file = {
					id: this.state.files[i].id,
					module: this.props.module,
					owner: this.state.files[i].owner,
					ext: this.state.files[i].ext,
					file_type: this.state.files[i].file_type,
					display_name: field.file,
					uploaded_at: this.state.files[i].uploaded_at,
					color: '#232d47',
					icon: 'check',
					button: 'edit'
				};
				console.log(files);
				files.push(file);
				found = true;
			}
		}

		if (!found) {
			var missingfile = {
				id: 0,
				module: this.props.module,
				display_name: field.file,
				uploaded_at: 'Never',
				color: '#7fa0ab',
				icon: 'question',
				button: 'upload'
			};
			files.push(missingfile);
		}

		return files;
	};

	displayButton = (item: any, file: any): any => {
		const that = this;
		var url =
			'/api/files/' +
			this.props.module +
			'/upload/' +
			this.state.parentId +
			'?id=' +
			file.id +
			'&dispname=' +
			item.file +
			'&external_id=' +
			item.id;

		const props = {
			name: 'file',
			action: url,
			headers: {
				authorization: 'authorization-text'
			},
			onSuccess(info: any) {
				that.getFiles();
			},
			onChange(info: any) {
				if (info.file.status === 'done') {
					message.success(`${info.file.name} file uploaded successfully`);
				} else if (info.file.status === 'error') {
					message.error(`${info.file.name} file upload failed`);
				}
			}
		};

		return [
			<Upload {...props} fileList={[]} multiple={false}>
				<Button size="default" type="default" title="Upload" shape="circle">
					<Icon type="upload" />
				</Button>
			</Upload>,
			<Button
				size="default"
				title="Preview"
				shape="circle"
				onClick={() => {
					this.setState({
						preview: {
							visible: true,
							type: item.ext,
							path: '/api/files/' + this.props.module + '/preview/' + file.id
						}
					});
				}}
			>
				<Icon type="ant-design" />
			</Button>,
			<Button
				size="default"
				onClick={() => {
					var url = '/api/files/' + this.props.module + '/download/' + file.id;
					window.open(url, '_blank');
				}}
				type="default"
				title="Download"
				shape="circle"
			>
				<Icon type="download" />
			</Button>,
			<Button
				size="default"
				onClick={() => {
					this.handleDeleteFile(file);
				}}
				type="default"
				title="Delete"
				shape="circle"
			>
				<Icon type="delete" />
			</Button>
		];
	};

	componentDidUpdate(prevProps: Props, prevState: State) {
		if (this.props.module !== prevProps.module) {
			this.getFiles();
		}
	}

	render() {
		return (
			<div>
				<Modal
					title="Preview"
					style={{ top: 20, height: '95%' }}
					footer={null}
					visible={this.state.preview.visible}
					width="90%"
					onCancel={() => {
						this.setState({
							preview: {
								visible: false,
								type: '',
								path: ''
							}
						});
					}}
				>
					<iframe src={this.state.preview.path} frameBorder="0" width="100%" height="90%" />
				</Modal>
				{this.props.requiredfiles.map((field) => {
					if (field.sub_role !== this.props.subRole) {
						return <div />;
					}
					if (field.disabled) {
						return <div />;
					}
					var files = this.showUploadSection(field);
					return (
						<div key={field.id}>
							<List
								itemLayout="horizontal"
								split={true}
								dataSource={files}
								renderItem={(item: any) => (
									<List.Item
										key={item.id}
										actions={this.displayButton(field, item)}
										style={{ textAlign: 'left' }}
									>
										<List.Item.Meta
											avatar={
												<Avatar
													shape="circle"
													icon={item.icon}
													size="large"
													style={{ color: '#ffffff', backgroundColor: item.color }}
												/>
											}
											description={<FileFolderDetails item={item} />}
											title={field.file}
										/>
									</List.Item>
								)}
							/>
							<Divider style={{ margin: 0 }} />
						</div>
					);
				})}
			</div>
		);
	}
}
