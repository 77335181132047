import React from 'react';
import { Input } from 'antd';
import FormComponentInterface from './FormComponentInterface';

interface Props {
    model: any;
    form: any;
    initialValue: string;
    style?: any;
}

interface Store {

}

export default class InputComponent extends React.Component <Props, Store> implements FormComponentInterface{
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                {getFieldDecorator(this.props.model.key, {
                    rules: [{required: this.props.model.required, message: this.props.model.message}],
                    initialValue: this.props.initialValue,
                })(
                    <Input style={this.props.style} placeholder={this.props.model.placeholder} name={this.props.model.key}/>
                )}
            </div>
        )
    }
}