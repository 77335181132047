import { observable, action } from 'mobx';
import { RequiredFile } from './models/RequiredFile';
import { message } from 'antd';

const roles = require('../schemas/roles.json');

class SettingsStore {
	@observable public forms: any;
	@observable public data: RequiredFile[];

	constructor() {
		this.forms = {};
		this.data = new Array(...roles);
		this.getSettings();
	}

	getSettings() {
		fetch('/api/requiredFiles', {
			method: 'get',
			credentials: 'same-origin'
		})
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				this.load(data.data);
			});
	}

	@action.bound
	load = (data: any) => {
		for (let i: number = 0; i < this.data.length; i++) {
			for (let j: number = 0; j < data.length; j++) {
				if (this.data[i].role === data[j].role) {
					this.data[i].file_expiry = [];
					data[j].file_expiry.map((fe) => this.data[i].file_expiry.push(fe));
				}
			}
		}
	};

	@action.bound
	public removeItem = (id: number) => {
		console.log(id);
	};

	private handleSubmit = (data: any) => {
		fetch('/api/requiredFiles', {
			method: 'post',
			credentials: 'same-origin',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		})
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				if (data.success) {
					message.success('Information Saved');
					this.load(data.data);
				}
			});
	};

	public registerForm = (role: any, form: any) => {
		this.forms[role] = form;
	};

	@action.bound
	public addNewItem = (role: string, item: any) => {
		let data = this.data;
		for (let i = 0; i < data.length; i++) {
			if (data[i].role === role) {
				data[i].file_expiry.push(item);
				break;
			}
		}
		this.handleSubmit(data);
	};

	@action.bound
	public disableItem = (id: number) => {
		this.data.forEach((e) => {
			e.file_expiry.forEach((f) => {
				if (f.id === id) {
					f.disabled = !f.disabled;
				}
			});
		});
	};

	@action.bound
	public saveForms = () => {
		let data = this.data;
		let values = {};
		let error = false;
		for (let k in this.forms) {
			this.forms[k].validateFields((err, vals) => {
				if (!err) {
					values = { ...values, ...vals };
				} else {
					error = true;
				}
			});
			if (error) {
				break;
			}
		}
		if (!error) {
			for (let i = 0; i < data.length; i++) {
				for (let x = 0; x < data[i].file_expiry.length; x++) {
					let id = data[i].file_expiry[x].id;
					if (id > 0) {
						if (values['file:' + id]) {
							data[i].file_expiry[x].file = values['file:' + id];
						}
						if (values['period:' + id]) {
							data[i].file_expiry[x].period = values['period:' + id];
						}
						if (values['amount:' + id]) {
							data[i].file_expiry[x].amount = values['amount:' + id];
						}
						if (values['subrole:' + id]) {
							data[i].file_expiry[x].sub_role = values['subrole:' + id];
						}
					}
				}
			}
			this.handleSubmit(data);
		} else {
			message.error('Validation error');
		}
	};

	@action
	getFields = (values: any) => {
		var data = this.data;
		for (var i: number = 0; i < data.length; i++) {
			if (values.role === data[i].role) {
				data[i] = values;
			}
		}
		this.data = data;
	};
}

const settingsStore = new SettingsStore();
export { settingsStore, SettingsStore };
