import React from 'react';
import { Menu, Icon, Layout } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
const { Sider } = Layout;
import { uiStore, UIStore } from '../stores/Store';

@inject(() => ({ ui: uiStore as UIStore }))
@observer
class SideMenuBar extends React.Component<{ ui?: UIStore; location?: any }, {}> {
	ui: UIStore;
	constructor(props: any) {
		super(props);
		this.ui = props.ui;
	}

	public render() {
		return (
			<Sider
				collapsible
				collapsed={this.ui.isSideMenuCollapsed}
				onCollapse={this.ui.toggleSideMenu}
				style={{
					overflow: 'auto',
					height: '100vh',
					zIndex: 999,
					position: 'fixed',
					left: 0
				}}
			>
				<div className="logo" />
				<Menu theme="dark" mode="inline" defaultSelectedKeys={[ 'users' ]}>
					<Menu.Item key="users">
						<Link to="/users">
							<Icon type="user" />
							<span className="nav-text">Users</span>
						</Link>
					</Menu.Item>
					<Menu.Item key="files">
						<Link to="/files">
							<Icon type="file" />
							<span className="nav-text">Documents</span>
						</Link>
					</Menu.Item>
					<Menu.Item key="settings">
						<Link to="/settings">
							<Icon type="setting" />
							<span className="nav-text">Settings</span>
						</Link>
					</Menu.Item>
				</Menu>
			</Sider>
		);
	}
}
export const SideMenu = withRouter(SideMenuBar);
