import React from 'react';
import { Form, Tabs, Button, Divider } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { observer, inject } from 'mobx-react';
import SettingsDetails from '../components/SettingsForm';
import { settingsStore, SettingsStore } from '../stores/SettingsStore';
const { TabPane } = Tabs;
interface ISettingsScreen {
	store: SettingsStore;
}

@inject(() => ({ store: settingsStore as SettingsStore }))
@observer
class SettingsScreen extends React.Component<ISettingsScreen & FormComponentProps, {}> {
	public constructor(props: ISettingsScreen & FormComponentProps) {
		super(props);
	}

	render() {
		const { store } = this.props;
		return (
			<div>
				<div style={{ float: 'left' }}>
					<h1>Required files per role</h1>
				</div>
				<div style={{ float: 'right' }}>
					<Button type="primary" onClick={store.saveForms}>
						Save
					</Button>
				</div>
				<Divider orientation="left" style={{ marginTop: 0 }} />
				<Tabs defaultActiveKey="tab-settings-0" type="line" tabPosition="left" animated={true}>
					{store.data.map((d, i) => {
						return (
							<TabPane tab={d.role} key={'tab-settings-' + i}>
								<SettingsDetails store={store} data={d} />
							</TabPane>
						);
					})}
				</Tabs>
			</div>
		);
	}
}

export default Form.create()(SettingsScreen);
