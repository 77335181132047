import React from 'react';
import { Mention } from 'antd';
import FormComponentInterface from './FormComponentInterface';

// const { toString } = Mention;

interface Props {
    model: any;
    form: any;
    initialValue: string;
}

interface Store {
    suggestions: any[];
}

export default class MentionComponent extends React.Component <Props, Store> implements FormComponentInterface {
    constructor(props: Props) {
        super(props);
        this.state = {
            suggestions: [],
        }
    }

    componentWillMount() {
        for(let i:number = 0; i<this.props.model.options.length; i++) {
            this.state.suggestions.push(this.props.model.options[i].value);
        }
    }

    render() {
        console.log(this.state.suggestions);
        const { getFieldDecorator } = this.props.form;

        return(
            <div>
                {getFieldDecorator(this.props.model.key, {
                    rules: [{required: this.props.model.required, message: this.props.model.message}],
                    initialValue: this.props.initialValue,
                })(
                    <Mention placeholder={this.props.model.placeholder} suggestions={this.state.suggestions}/>
                )}
            </div>
        )
    }
}