import { observable, action, ObservableMap } from 'mobx';
import Customer from './CustomerClass';
import ICustomer from './CustomerClass';
import moment from 'moment';
import { message } from 'antd';

export const initialCustomer: ICustomer = {
	id: 0,
	id_card: '',
	organization_id: 0,
	first_name: '',
	last_name: '',
	password: '',
	email_address: '',
	telephone: '',
	mobile: '',
	address: '',
	address2: '',
	address3: '',
	address4: '',
	can_login: false,
	roles: '',
	ni_number: '',
	status: '',
	citizenship: '',
	birth_date: moment('01/01/01').format('DD/MM/YY'),
	nationality: '',
	current_status: '',
	passport_number: '',
	children: '',
	children_birth_dates_csv: '',
	bank_name: '',
	bank_address: '',
	bank_account_number: '',
	iban_number: '',
	employment_date: moment(),
	resignation_date: moment(),
	last_employment_date: moment(),
	director_of_fund: false,
	subscriber_name: '',
	trading_name: '',
	registered_address: '',
	account_name: '',
	bank_swift_code: '',
	contact_at_bank: '',
	contact_name: '',
	bank_telephone_number: '',
	source_of_funds: '',
	class_of_shares: '',
	amount_subscribed_number_of_shares: '',
	dealing_date: moment(),
	mlro_approved: '',
	company_name: '',
	registration_number: '',
	country_of_domicile: '',
	regulator: '',
	service_provided: '',
	name_of_parent_company: '',
	compliance_officer_details: '',
	mlro_and_function_details: '',
	internal_control_function_details: '',
	auditors: '',
	auditors_address: '',
	pending_lawsuits_against_company: '',
	lawsuit_details_against_company: '',
	pending_lawsuits_init_by_company: '',
	lawsuit_details_init_by_company: '',
	warning_issued: '',
	company_breach_of_licence: '',
	details_company_breach_of_licence: '',
	service_breach_of_licence: '',
	details_service_breach_of_licence: '',
	disputes_referred_to_arbitration: '',
	details_disputes_referred_to_arbitration: '',
	business_contingency_disaster_recovery_plan: '',
	last_revision_complete_date: moment(),
	plans_revised: '',
	requirement_remedial_measures: '',
	in_house: '',
	details_improvement_enhancements: '',
	it_changes_future: '',
	details_it_changes_future: '',
	risk_score_result: '',
	service_provider_comments: '',
	compliance_officer_sign_off_date: moment(),
	director_ubo: '',
	ubo_licenced_regulated: '',
	details_ubo_licenced_regulated: '',
	ubo_hold_regulatory_approvals: '',
	surrendered_ceased: '',
	application_withdrawn: '',
	maritial_status: '',
	sub_role: ''
};

interface IRequiredFilesRole {
	role: string;
	file_expiry: IRequiredFile[];
}

interface IRequiredFile {
	id: number;
	file: string;
	period: number;
	ammount: number;
	sub_role: string;
	disabled: string;
}

export class CustomerStore {
	@observable public customers: Customer[];
	@observable public current_customer: Customer;
	@observable public required_files: ObservableMap;
	@observable public selected_role: string;
	@observable public last_created_id: number;

	constructor() {
		this.customers = new Array();
		this.current_customer = new Customer(initialCustomer);
		this.required_files = new ObservableMap();
	}

	@action
	initialiseCurrentCustomer = (customer: ICustomer) => {
		this.current_customer = new Customer(customer);
	};

	@action
	addCustomers = (data: any): void => {
		if (data) {
			for (let i: number = 0; i < data.data.length; i++) {
				var customer = new Customer(data.data[i] as ICustomer);
				this.customers[i] = customer;
			}
		}
	};

	@action
	loadRequiredFiles = (data) => {
		const d = data.data;
		d.forEach((e: IRequiredFilesRole) => {
			console.log(e);
			this.required_files.set(e.role, e.file_expiry as IRequiredFile[]);
		});
	};

	fetchRequiredFiles = () => {
		fetch('/api/requiredFiles', {
			method: 'get',
			credentials: 'same-origin'
		})
			.then((res) => {
				return res.json();
			})
			.then(this.loadRequiredFiles);
	};

	@action
	getCustomers = (role: string, q: string): void => {
		this.selected_role = role;
		this.customers = new Array();
		fetch('/api/users?role=' + role + '&q=' + q, {
			method: 'get',
			credentials: 'same-origin'
		})
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				this.addCustomers(data);
			});
	};

	@action
	setLastCreated = (id: number) => {
		this.last_created_id = id;
	};

	@action
	editCustomer = (data: any): void => {
		if (data.user) {
			var customer = new Customer(data.user as ICustomer);
			this.current_customer = customer;

			if (customer.id !== 0) {
				for (var i: number = 0; i < this.customers.length; i++) {
					if (this.customers[i].id === customer.id) {
						this.customers[i] = customer;
						return;
					}
				}
			} else {
				this.customers.push(customer);
			}
		} else if (data.data) {
			var customer = new Customer(data.data as ICustomer);
			this.current_customer = customer;

			if (customer.id !== 0) {
				for (var i: number = 0; i < this.customers.length; i++) {
					if (this.customers[i].id === customer.id) {
						this.customers[i] = customer;
						return;
					}
				}
			} else {
				this.customers.push(customer);
			}
		}
	};

	@action
	getOneCustomer = (id: number): void => {
		fetch('/api/users/' + id, {
			method: 'get',
			credentials: 'same-origin'
		})
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				this.editCustomer(data);
			});
	};

	@action
	updateOrCreateCustomer = (customer: Customer, callback?: any): void => {
		if (customer.id === 0) {
			fetch('/api/users/0', {
				method: 'put',
				credentials: 'same-origin',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(customer)
			})
				.then((res) => {
					return res.json();
				})
				.then((data) => {
					if (data.success) {
						message.success('User created');
						this.initialiseCurrentCustomer(data);
						this.setLastCreated(data.data.id);
						callback(data.data);
					} else {
						if (data.message != '') {
							message.info(data.message);
						}
					}
				});
		} else {
			fetch('/api/users/' + customer.id, {
				method: 'put',
				credentials: 'same-origin',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(customer)
			})
				.then((res) => {
					return res.json();
				})
				.then((data) => {
					if (data.message != '') {
						message.info(data.message);
					}
					this.editCustomer(data);
					callback(data);
				});
		}
	};

	@action
	removeCustomer = (id: number): void => {
		for (let i: number = 0; i < this.customers.length; i++) {
			if (this.customers[i].id === id) {
				this.customers.splice(i, 1);
				break;
			}
		}
	};

	@action
	deleteCustomer = (id: number): void => {
		fetch('/api/users/' + id, {
			method: 'delete',
			credentials: 'same-origin'
		})
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				this.removeCustomer(id);
			});
	};
}

export const customerStore = new CustomerStore();
// export customerStore;
