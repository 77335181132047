import { observable } from 'mobx';
import moment from 'moment';

interface ICustomer {
	id: number;
	id_card: string;
	organization_id: number;
	first_name: string;
	last_name: string;
	password: string;
	email_address: string;
	telephone: string;
	mobile: string;
	address: string;
	address2: string;
	address3: string;
	address4: string;
	can_login: boolean;
	roles: string;
	ni_number: string;
	status: string;
	citizenship: string;
	birth_date: string;
	nationality: string;
	current_status: string;
	passport_number: string;
	children: string;
	children_birth_dates_csv: string;
	bank_name: string;
	bank_address: string;
	bank_account_number: string;
	iban_number: string;
	employment_date: moment.Moment;
	resignation_date: moment.Moment;
	last_employment_date: moment.Moment;
	director_of_fund: boolean;
	subscriber_name: string;
	trading_name: string;
	registered_address: string;
	account_name: string;
	bank_swift_code: string;
	contact_name: string;
	contact_at_bank: string;
	bank_telephone_number: string;
	source_of_funds: string;
	class_of_shares: string;
	amount_subscribed_number_of_shares: string;
	dealing_date: moment.Moment;
	mlro_approved: string;
	company_name: string;
	registration_number: string;
	country_of_domicile: string;
	regulator: string;
	service_provided: string;
	name_of_parent_company: string;
	compliance_officer_details: string;
	mlro_and_function_details: string;
	internal_control_function_details: string;
	auditors: string;
	auditors_address: string;
	pending_lawsuits_against_company: string;
	lawsuit_details_against_company: string;
	pending_lawsuits_init_by_company: string;
	lawsuit_details_init_by_company: string;
	warning_issued: string;
	company_breach_of_licence: string;
	details_company_breach_of_licence: string;
	service_breach_of_licence: string;
	details_service_breach_of_licence: string;
	disputes_referred_to_arbitration: string;
	details_disputes_referred_to_arbitration: string;
	business_contingency_disaster_recovery_plan: string;
	last_revision_complete_date: moment.Moment;
	plans_revised: string;
	requirement_remedial_measures: string;
	in_house: string;
	details_improvement_enhancements: string;
	it_changes_future: string;
	details_it_changes_future: string;
	risk_score_result: string;
	service_provider_comments: string;
	compliance_officer_sign_off_date: moment.Moment;
	director_ubo: string;
	ubo_licenced_regulated: string;
	details_ubo_licenced_regulated: string;
	ubo_hold_regulatory_approvals: string;
	surrendered_ceased: string;
	application_withdrawn: string;
	maritial_status: string;
	sub_role: string;
}

export default class Customer {
	@observable id: number;
	@observable id_card: string;
	@observable organization_id: number;
	@observable first_name: string;
	@observable last_name: string;
	@observable password: string;
	@observable email_address: string;
	@observable telephone: string;
	@observable mobile: string;
	@observable address: string;
	@observable address2: string;
	@observable address3: string;
	@observable address4: string;
	@observable can_login: boolean;
	@observable roles: string;
	@observable ni_number: string;
	@observable status: string;
	@observable citizenship: string;
	@observable birth_date: string;
	@observable nationality: string;
	@observable current_status: string;
	@observable passport_number: string;
	@observable children: string;
	@observable children_birth_dates_csv: string;
	@observable bank_name: string;
	@observable bank_address: string;
	@observable bank_account_number: string;
	@observable iban_number: string;
	@observable employment_date: moment.Moment;
	@observable resignation_date: moment.Moment;
	@observable last_employment_date: moment.Moment;
	@observable director_of_fund: boolean;
	@observable subscriber_name: string;
	@observable trading_name: string;
	@observable registered_address: string;
	@observable account_name: string;
	@observable bank_swift_code: string;
	@observable contact_at_bank: string;
	@observable contact_name: string;
	@observable bank_telephone_number: string;
	@observable source_of_funds: string;
	@observable class_of_shares: string;
	@observable amount_subscribed_number_of_shares: string;
	@observable dealing_date: moment.Moment;
	@observable mlro_approved: string;
	@observable company_name: string;
	@observable registration_number: string;
	@observable country_of_domicile: string;
	@observable regulator: string;
	@observable service_provided: string;
	@observable name_of_parent_company: string;
	@observable compliance_officer_details: string;
	@observable mlro_and_function_details: string;
	@observable internal_control_function_details: string;
	@observable auditors: string;
	@observable auditors_address: string;
	@observable pending_lawsuits_against_company: string;
	@observable lawsuit_details_against_company: string;
	@observable pending_lawsuits_init_by_company: string;
	@observable lawsuit_details_init_by_company: string;
	@observable warning_issued: string;
	@observable company_breach_of_licence: string;
	@observable details_company_breach_of_licence: string;
	@observable service_breach_of_licence: string;
	@observable details_service_breach_of_licence: string;
	@observable disputes_referred_to_arbitration: string;
	@observable details_disputes_referred_to_arbitration: string;
	@observable business_contingency_disaster_recovery_plan: string;
	@observable last_revision_complete_date: moment.Moment;
	@observable plans_revised: string;
	@observable requirement_remedial_measures: string;
	@observable in_house: string;
	@observable details_improvement_enhancements: string;
	@observable it_changes_future: string;
	@observable details_it_changes_future: string;
	@observable risk_score_result: string;
	@observable service_provider_comments: string;
	@observable compliance_officer_sign_off_date: moment.Moment;
	@observable director_ubo: string;
	@observable ubo_licenced_regulated: string;
	@observable details_ubo_licenced_regulated: string;
	@observable ubo_hold_regulatory_approvals: string;
	@observable surrendered_ceased: string;
	@observable application_withdrawn: string;
	@observable maritial_status: string;
	@observable sub_role: string;

	constructor(customer: ICustomer) {
		if (customer.id !== null) {
			this.id = customer.id;
		}

		if (customer.id_card !== null) {
			this.id_card = customer.id_card;
		}

		if (customer.organization_id !== null) {
			this.organization_id = customer.organization_id;
		}
		if (customer.first_name !== null) {
			this.first_name = customer.first_name;
		}
		if (customer.last_name !== null) {
			this.last_name = customer.last_name;
		}
		if (customer.password !== null) {
			this.password = customer.password;
		}
		if (customer.email_address !== null) {
			this.email_address = customer.email_address;
		}
		if (customer.telephone !== null) {
			this.telephone = customer.telephone;
		}
		if (customer.mobile !== null) {
			this.mobile = customer.mobile;
		}

		if (customer.address !== null) {
			this.address = customer.address;
		}
		if (customer.address2 !== null) {
			this.address2 = customer.address2;
		}
		if (customer.address3 !== null) {
			this.address3 = customer.address3;
		}
		if (customer.address4 !== null) {
			this.address4 = customer.address4;
		}
		if (customer.can_login !== null) {
			this.can_login = customer.can_login;
		}
		if (customer.roles !== null) {
			this.roles = customer.roles;
		}
		if (customer.ni_number !== null) {
			this.ni_number = customer.ni_number;
		}
		if (customer.status !== null) {
			this.status = customer.status;
		}
		if (customer.citizenship !== null) {
			this.citizenship = customer.citizenship;
		}
		if (customer.birth_date !== null) {
			this.birth_date = customer.birth_date;
		}
		if (customer.nationality !== null) {
			this.nationality = customer.nationality;
		}
		if (customer.current_status !== null) {
			this.current_status = customer.current_status;
		}

		if (customer.passport_number !== null) {
			this.passport_number = customer.passport_number;
		}
		if (customer.children !== null) {
			this.children = customer.children;
		}
		if (customer.children_birth_dates_csv !== null) {
			this.children_birth_dates_csv = customer.children_birth_dates_csv;
		}
		if (customer.bank_name !== null) {
			this.bank_name = customer.bank_name;
		}
		if (customer.bank_address !== null) {
			this.bank_address = customer.bank_address;
		}
		if (customer.bank_account_number !== null) {
			this.bank_account_number = customer.bank_account_number;
		}
		if (customer.iban_number !== null) {
			this.iban_number = customer.iban_number;
		}
		if (customer.employment_date !== null) {
			this.employment_date = customer.employment_date;
		}
		if (customer.resignation_date !== null) {
			this.resignation_date = customer.resignation_date;
		}
		if (customer.last_employment_date !== null) {
			this.last_employment_date = customer.last_employment_date;
		}
		if (customer.director_of_fund !== null) {
			this.director_of_fund = customer.director_of_fund;
		}
		if (customer.subscriber_name !== null) {
			this.subscriber_name = customer.subscriber_name;
		}
		if (customer.trading_name !== null) {
			this.trading_name = customer.trading_name;
		}
		if (customer.registered_address !== null) {
			this.registered_address = customer.registered_address;
		}
		if (customer.account_name !== null) {
			this.account_name = customer.account_name;
		}
		if (customer.bank_swift_code !== null) {
			this.bank_swift_code = customer.bank_swift_code;
		}
		if (customer.contact_at_bank !== null) {
			this.contact_at_bank = customer.contact_at_bank;
		}
		if (customer.contact_name !== null) {
			this.contact_name = customer.contact_name;
		}
		if (customer.bank_telephone_number !== null) {
			this.bank_telephone_number = customer.bank_telephone_number;
		}
		if (customer.source_of_funds !== null) {
			this.source_of_funds = customer.source_of_funds;
		}
		if (customer.class_of_shares !== null) {
			this.class_of_shares = customer.class_of_shares;
		}
		if (customer.amount_subscribed_number_of_shares !== null) {
			this.amount_subscribed_number_of_shares = customer.amount_subscribed_number_of_shares;
		}
		if (customer.dealing_date !== null) {
			this.dealing_date = customer.dealing_date;
		}
		if (customer.mlro_approved !== null) {
			this.mlro_approved = customer.mlro_approved;
		}
		if (customer.company_name !== null) {
			this.company_name = customer.company_name;
		}
		if (customer.registration_number !== null) {
			this.registration_number = customer.registration_number;
		}
		if (customer.country_of_domicile !== null) {
			this.country_of_domicile = customer.country_of_domicile;
		}
		if (customer.regulator !== null) {
			this.regulator = customer.regulator;
		}
		if (customer.service_provided !== null) {
			this.service_provided = customer.service_provided;
		}
		if (customer.name_of_parent_company !== null) {
			this.name_of_parent_company = customer.name_of_parent_company;
		}
		if (customer.compliance_officer_details !== null) {
			this.compliance_officer_details = customer.compliance_officer_details;
		}
		if (customer.mlro_and_function_details !== null) {
			this.mlro_and_function_details = customer.mlro_and_function_details;
		}
		if (customer.internal_control_function_details !== null) {
			this.internal_control_function_details = customer.internal_control_function_details;
		}
		if (customer.auditors !== null) {
			this.auditors = customer.auditors;
		}
		if (customer.auditors_address !== null) {
			this.auditors_address = customer.auditors_address;
		}
		if (customer.pending_lawsuits_against_company !== null) {
			this.pending_lawsuits_against_company = customer.pending_lawsuits_against_company;
		}
		if (customer.lawsuit_details_against_company !== null) {
			this.lawsuit_details_against_company = customer.lawsuit_details_against_company;
		}
		if (customer.lawsuit_details_init_by_company !== null) {
			this.lawsuit_details_init_by_company = customer.lawsuit_details_init_by_company;
		}
		if (customer.warning_issued !== null) {
			this.warning_issued = customer.warning_issued;
		}
		if (customer.company_breach_of_licence !== null) {
			this.company_breach_of_licence = customer.company_breach_of_licence;
		}
		if (customer.details_company_breach_of_licence !== null) {
			this.details_company_breach_of_licence = customer.details_company_breach_of_licence;
		}
		if (customer.service_breach_of_licence !== null) {
			this.service_breach_of_licence = customer.service_breach_of_licence;
		}
		if (customer.details_service_breach_of_licence !== null) {
			this.details_service_breach_of_licence = customer.details_service_breach_of_licence;
		}
		if (customer.disputes_referred_to_arbitration !== null) {
			this.disputes_referred_to_arbitration = customer.disputes_referred_to_arbitration;
		}
		if (customer.details_disputes_referred_to_arbitration !== null) {
			this.details_disputes_referred_to_arbitration = customer.details_company_breach_of_licence;
		}
		if (customer.business_contingency_disaster_recovery_plan !== null) {
			this.business_contingency_disaster_recovery_plan = customer.business_contingency_disaster_recovery_plan;
		}
		if (customer.last_revision_complete_date !== null) {
			this.last_revision_complete_date = customer.last_revision_complete_date;
		}
		if (customer.plans_revised !== null) {
			this.plans_revised = customer.plans_revised;
		}
		if (customer.requirement_remedial_measures !== null) {
			this.requirement_remedial_measures = customer.requirement_remedial_measures;
		}
		if (customer.in_house !== null) {
			this.in_house = customer.in_house;
		}
		if (customer.details_improvement_enhancements !== null) {
			this.details_improvement_enhancements = customer.details_improvement_enhancements;
		}
		if (customer.it_changes_future !== null) {
			this.it_changes_future = customer.it_changes_future;
		}
		if (customer.details_it_changes_future !== null) {
			this.details_it_changes_future = customer.details_it_changes_future;
		}
		if (customer.risk_score_result !== null) {
			this.risk_score_result = customer.risk_score_result;
		}
		if (customer.service_provider_comments !== null) {
			this.service_provider_comments = customer.service_provider_comments;
		}
		if (customer.compliance_officer_sign_off_date !== null) {
			this.compliance_officer_details = customer.compliance_officer_details;
		}
		if (customer.director_ubo !== null) {
			this.director_ubo = customer.director_ubo;
		}
		if (customer.ubo_licenced_regulated !== null) {
			this.ubo_licenced_regulated = customer.ubo_licenced_regulated;
		}
		if (customer.details_ubo_licenced_regulated !== null) {
			this.details_ubo_licenced_regulated = customer.details_ubo_licenced_regulated;
		}
		if (customer.ubo_hold_regulatory_approvals !== null) {
			this.ubo_hold_regulatory_approvals = customer.ubo_hold_regulatory_approvals;
		}
		if (customer.surrendered_ceased !== null) {
			this.surrendered_ceased = customer.surrendered_ceased;
		}
		if (customer.application_withdrawn !== null) {
			this.application_withdrawn = customer.application_withdrawn;
		}
		if (customer.maritial_status !== null) {
			this.maritial_status = customer.maritial_status;
		}
		if (customer.sub_role !== null) {
			this.sub_role = customer.sub_role;
		}
	}
}
