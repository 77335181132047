import React from 'react';
import { Row, Col, Form, Divider, Button, message } from 'antd';
import InputComponent from './InputComponent';
import InputNumberComponent from './InputNumberComponent';
import SelectComponent from './SelectComponent';
import MultipleSelectComponent from './MultipleSelectComponent';
import MentionComponent from './MentionComponent';
import DatePickerComponent from './DatePickerComponent';
import TimePickerComponent from './TimePickerComponent';
import CheckboxComponent from './CheckboxComponent';
import RadioComponent from './RadioComponent';
import RateComponent from './RateComponent';
import SliderComponent from './SliderComponent';
import SliderRangeComponent from './SliderRangeComponent';
import SwitchComponent from './SwitchComponent';
import { FormComponentProps } from 'antd/lib/form/Form';
import moment from 'moment';

const FormItem = Form.Item;

interface Props {
	schema: any;
	id: number;
	save: any;
	data: any;
}

interface State {
	id: number;
	birth_date: moment.Moment;
}

const elements: any = {};

const registerComponent = (index: string, component: any): void => {
	elements[index] = component;
};

registerComponent('input', InputComponent);
registerComponent('inputnumber', InputNumberComponent);
registerComponent('select', SelectComponent);
registerComponent('multipleselect', MultipleSelectComponent);
registerComponent('mention', MentionComponent);
registerComponent('radio', RadioComponent);
registerComponent('slider', SliderComponent);
registerComponent('sliderrange', SliderRangeComponent);
registerComponent('rate', RateComponent);
registerComponent('checkbox', CheckboxComponent);
registerComponent('switch', SwitchComponent);
registerComponent('datepicker', DatePickerComponent);
registerComponent('timepicker', TimePickerComponent);

class FormBuilderComponent extends React.Component<Props & FormComponentProps, State> {
	constructor(props: Props & FormComponentProps) {
		super(props);
		this.state = {
			id: 0,
			birth_date: moment('dd-mm-yyyy')
		};
	}

	handleSubmit = (e: any) => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				this.props.save(values);
			} else {
				message.info('Some fields are missing');
			}
		});
	};

	render() {
		var Element;
		const formItemLayout = {
			labelCol: {
				xs: { span: 24 },
				sm: { span: 10 }
			},
			wrapperCol: {
				xs: { span: 24 },
				sm: { span: 14 }
			}
		};
		const { schema, data } = this.props;
		if (!schema) {
			return <div>Error schema not defined</div>;
		}
		return (
			<div className={schema.className}>
				<Row>
					<Col offset={0} xs={22} sm={22} md={22} lg={22} xl={22}>
						<Form className="dynamic-form" onSubmit={this.handleSubmit}>
							{schema.model.map((m, i) => {
								return (
									<div key={m.key + i}>
										{m.tab ? (
											<Divider type="horizontal" orientation="right">
												{m.tab}
											</Divider>
										) : null}
										{m.fields.map((t) => {
											let fItemLayout = t.formItemLayout ? t.formItemLayout : formItemLayout;
											Element = elements[t.type];
											return (
												<FormItem {...fItemLayout} label={t.label} key={t.key}>
													<Element
														form={this.props.form}
														model={t}
														initialValue={data[t.key]}
													/>
												</FormItem>
											);
										})}
									</div>
								);
							})}
							<Divider type="horizontal" />
							<Button type="primary" htmlType="submit">
								Save
							</Button>
						</Form>
					</Col>
				</Row>
			</div>
		);
	}
}

const FormBuilder = Form.create<Props>()(FormBuilderComponent);
export default FormBuilder;
