import React from 'react';
import { Checkbox } from 'antd';
import FormComponentInterface from './FormComponentInterface';

interface Props {
    model: any;
    form: any;
    initialValue: boolean;
}

interface Store {
    checked: boolean;
}

export default class CheckBoxComponent extends React.Component <Props, Store> implements FormComponentInterface {
    constructor(props: Props) {
        super(props);
        this.state = {
            checked: this.props.initialValue,
        }
    }

    componentDidMount() {
        this.props.form.setFieldsValue({
            [this.props.model.key]: this.props.initialValue,
        })
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        
        if(prevProps.initialValue !== this.props.initialValue) {
            this.setState({
                checked: this.props.initialValue,
            })

            this.props.form.setFieldsValue({
                [this.props.model.key]: this.props.initialValue,
            })
        }
    }

    handleChange = (e: any) => {
        this.setState({
            checked: e.target.checked,
        })
        this.props.form.setFieldsValue({
            [this.props.model.key]: e.target.checked,
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return(
            <div>
                {getFieldDecorator(this.props.model.key, {
                })(
                    <Checkbox checked={this.state.checked} onChange={this.handleChange}>{this.props.model.label}</Checkbox>
                )} 
            </div>
        )
    }
}