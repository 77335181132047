import React from 'react';
import { Select } from 'antd';
import FormComponentInterface from './FormComponentInterface';

const Option = Select.Option;

interface Props {
    model: any;
    form: any;
    initialValue: string;
}

interface Store {
}

export default class MultipleSelectComponent extends React.Component <Props, Store> implements FormComponentInterface {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        var options: string[] = new Array();
        for(var i: number = 0; i<this.props.model.options.length; i++) {
            options.push(this.props.model.options[i].value)
        }

        var initialValues: string[] = new Array();

        if(this.props.initialValue) {
            initialValues = this.props.initialValue.split(", ");
        }
        
        return(
            <div>
                {getFieldDecorator(this.props.model.key, {
                    rules: [{required: this.props.model.required, message: this.props.model.message}],
                    initialValue: initialValues,
                })(
                    <Select 
                        showSearch={true}
                        mode="multiple"
                        placeholder={this.props.model.placeholder} 
                        optionFilterProp="childrenn"
                    >
                        {options.map((o: any) => (
                            <Option key={o}>{o}</Option>
                        ))}
                    </Select>
                 )}
             </div>
        )
    }
}