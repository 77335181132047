import React from 'react';
import Login from '../screens/LoginScreen';
import { Main } from './Main';
import { inject, observer } from 'mobx-react';
import { uiStore, UIStore } from '../stores/Store';

@inject(() => ({ store: uiStore as UIStore }))
@observer
class App extends React.Component<{ store: UIStore }, {}> {
	public store: UIStore;
	public constructor(props: { store: UIStore }) {
		super(props);
		this.store = uiStore;
	}

	componentWillMount() {
		const { store } = this;
		store.init();
	}

	public render() {
		return <div>{this.store.isLoggedIn ? <Main /> : <Login store={this.store} />}</div>;
	}
}

export default App;
