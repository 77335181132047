import React from 'react';
import { TimePicker } from 'antd';
import FormComponentInterface from './FormComponentInterface';

interface Props {
    model: any;
    form: any;
    initialValue: string;
}

interface Store {

}

export default class TimePickerComponent extends React.Component <Props, Store> implements FormComponentInterface {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        return(
            <div>
                {getFieldDecorator(this.props.model.key, {
                    rules: [{required: this.props.model.required, message: this.props.model.message}],
                    initialValue: this.props.initialValue,
                })(
                    <TimePicker 
                        placeholder={this.props.model.placeholder}
                        format="HH:mm:ss"
                    />
                )}
            </div>
        )
    }
}