import React, { SFC } from 'react';
import { Row, Button, Tabs, Icon, Popconfirm, Col, Dropdown, Menu, List, Avatar, Input, Divider } from 'antd';
import { customerStore, CustomerStore, initialCustomer } from './CustomerStore';
import Customer from './CustomerClass';
import ICustomer from './CustomerClass';
import { observer, inject } from 'mobx-react';
import { Link } from 'react-router-dom';
const Search = Input.Search;

const EmployeeDetails: SFC<{ item: ICustomer }> = (props) => (
	<Row>
		{props.item.email_address != '' ? (
			<Col md={8} sm={24}>
				<Icon type="mail" /> {props.item.email_address}
			</Col>
		) : null}
		{props.item.address != '' ? (
			<Col md={8} sm={24}>
				<Icon type="home" /> {props.item.address}
			</Col>
		) : null}
		{props.item.telephone != '' ? (
			<Col md={6} sm={24}>
				<Icon type="phone" /> {props.item.telephone} - {props.item.mobile}
			</Col>
		) : null}
	</Row>
);

const ServiceProviderDetails: SFC<{ item: ICustomer }> = (props) => (
	<Row>
		{props.item.address != '' ? (
			<Col sm={24}>
				<Icon type="home" /> {props.item.address}
			</Col>
		) : null}
	</Row>
);

const ShareholderDetails: SFC<{ item: ICustomer }> = (props) => (
	<Row>
		{props.item.contact_name !== '' ? (
			<Col md={6} sm={24}>
				<Icon type="user" /> {props.item.contact_name}
			</Col>
		) : null}
		{props.item.email_address != '' ? (
			<Col md={6} sm={24}>
				<Icon type="mail" /> {props.item.email_address}
			</Col>
		) : null}
		{props.item.registered_address != '' ? (
			<Col md={6} sm={24}>
				<Icon type="home" /> {props.item.registered_address}
			</Col>
		) : null}
		{props.item.telephone != '' ? (
			<Col md={6} sm={24}>
				<Icon type="phone" /> {props.item.telephone}
			</Col>
		) : null}
	</Row>
);

const TabPane = Tabs.TabPane;
interface Props {
	store: CustomerStore;
}

interface State {
	editCustomer: number;
}

@inject(() => ({
	store: customerStore as CustomerStore
}))
@observer
export class CustomerScreen extends React.Component<Props, State> {
	store: CustomerStore;

	public constructor(props: Props) {
		super(props);
		this.store = props.store!;
		this.state = {
			editCustomer: 0
		};
	}

	public componentWillMount() {
		this.store.getCustomers('Management', '');
	}

	deleteCustomer = (id: number) => {
		this.store.deleteCustomer(id);
	};

	handleTabChange = (key: string) => {
		this.store.getCustomers(key, '');
	};

	showAll = (): void => {
		this.store.getCustomers('', '');
	};

	searchCustomer = (role: string, q: string) => {
		this.store.getCustomers(role, q);
	};

	handleAddCustomer = () => {
		this.store.initialiseCurrentCustomer(initialCustomer);
		this.setState({ editCustomer: 0 });
	};

	handleEditCustomer = (record: any) => {
		this.store.getOneCustomer(record);
		this.setState({ editCustomer: record });
	};

	displayContextMenu = (record: ICustomer) => {
		return [
			<span>
				<Link to={'/users/' + record.id}>
					<Button title="Edit" shape="circle-outline">
						<Icon type="edit" />
					</Button>
				</Link>
				&nbsp;
				<Popconfirm
					placement="rightTop"
					title={'Are you sure you want to delete this user?'}
					onConfirm={this.deleteCustomer.bind(this, record.id)}
					okText="Yes"
					cancelText="No"
				>
					<Button title="Delete" shape="circle-outline">
						<Icon type="close-circle-o" />
					</Button>
				</Popconfirm>
			</span>
		];
	};

	showEmployees = (customers: Customer[]) => {
		//const { customers } = this.props.store;
		return (
			<List
				itemLayout="horizontal"
				split={true}
				dataSource={customers}
				pagination={{
					pageSize: 10
				}}
				renderItem={(item: ICustomer) => (
					<List.Item actions={this.displayContextMenu(item)}>
						<List.Item.Meta
							style={{ textAlign: 'left' }}
							avatar={<Avatar shape="circle" icon="user" size="large" />}
							title={item.first_name + ' ' + item.last_name}
							description={<EmployeeDetails item={item} />}
						/>
					</List.Item>
				)}
			/>
		);
	};

	showShareHolders = (customers: Customer[]) => {
		//const {customers} = this.props.store;
		return (
			<List
				itemLayout="horizontal"
				pagination={{
					pageSize: 10
				}}
				split={true}
				dataSource={customers}
				renderItem={(item: ICustomer) => (
					<List.Item actions={this.displayContextMenu(item)}>
						<List.Item.Meta
							style={{ textAlign: 'left' }}
							avatar={<Avatar shape="circle" icon="profile" size="large" />}
							title={item.subscriber_name}
							description={<ShareholderDetails item={item} />}
						/>
					</List.Item>
				)}
			/>
		);
	};

	showServiceProviders = (customers: Customer[]) => {
		//const {customers} = this.props.store;
		return (
			<List
				itemLayout="horizontal"
				split={true}
				pagination={{
					pageSize: 10
				}}
				dataSource={customers}
				renderItem={(item: ICustomer) => (
					<List.Item actions={this.displayContextMenu(item)}>
						<List.Item.Meta
							style={{ textAlign: 'left' }}
							avatar={<Avatar shape="circle" icon="profile" size="large" />}
							title={item.company_name}
							description={<ServiceProviderDetails item={item} />}
						/>
					</List.Item>
				)}
			/>
		);
	};

	public render() {
		const { customers } = this.props.store;
		const filteredCustomers: Customer[] = [];
		for (let i: number = 0; i < customers.length; i++) {
			filteredCustomers.push(customers[i]);
		}

		const menuItems = (
			<Menu>
				<Menu.Item>
					<Link to="/users/create/Management" replace>
						Management
					</Link>
				</Menu.Item>
				<Menu.Item>
					<Link to="/users/create/Employee" replace>
						Employee
					</Link>
				</Menu.Item>
				<Menu.Item>
					<Link to="/users/create/Director" replace>
						Director
					</Link>
				</Menu.Item>
				<Menu.Item>
					<Link to="/users/create/Shareholder" replace>
						Shareholder
					</Link>
				</Menu.Item>
				<Menu.Item>
					<Link to="/users/create/Service Provider" replace>
						Service Provider
					</Link>
				</Menu.Item>
				<Menu.Item>
					<Link to="/users/create/UBOS" replace>
						UBOS
					</Link>
				</Menu.Item>
			</Menu>
		);
		const searchPlaceholder = 'Search in ' + this.props.store.selected_role;

		return (
			<div>
				<div style={{ float: 'left' }}>
					<Search
						placeholder={searchPlaceholder}
						size="large"
						onSearch={(value) => this.searchCustomer(this.props.store.selected_role, value)}
						style={{ width: 400, height: '40px' }}
					/>
				</div>
				<div style={{ float: 'right' }}>
					<Dropdown overlay={menuItems}>
						<Button type="primary" size="large" shape="circle">
							<Icon type="plus" />
						</Button>
					</Dropdown>
					<br />
					<br />
				</div>
				<Divider />
				<Tabs
					defaultActiveKey="Management"
					type="line"
					tabPosition="left"
					onChange={this.handleTabChange}
					animated={true}
				>
					<TabPane tab="Management" key="Management">
						{this.showEmployees(filteredCustomers)}
					</TabPane>
					<TabPane tab="Employees" key="Employee">
						{this.showEmployees(filteredCustomers)}
					</TabPane>
					<TabPane tab="Directors" key="Director">
						{this.showEmployees(filteredCustomers)}
					</TabPane>
					<TabPane tab="Shareholders" key="Shareholder">
						{this.showShareHolders(filteredCustomers)}
					</TabPane>
					<TabPane tab="Service Providers" key="Service Provider">
						{this.showServiceProviders(filteredCustomers)}
					</TabPane>
					<TabPane tab="UBOS" key="UBOS">
						{this.showEmployees(filteredCustomers)}
					</TabPane>
				</Tabs>
			</div>
		);
	}
}
