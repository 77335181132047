import React from 'react';
import { observer, inject } from 'mobx-react';
import { action } from 'mobx';
import { Form, Row, Col, Tabs, Button, Icon, Divider } from 'antd';
import { customerStore, CustomerStore, initialCustomer } from './CustomerStore';
import { FormComponentProps } from 'antd/lib/form/Form';
import { Link } from 'react-router-dom';
import FileList from 'core/files/FileList';
import { toJS } from 'mobx';
import FormBuilder from '../../core/adminFiles/FormBuilderComponent';

const { TabPane } = Tabs;

const FORMS = {
	Employee: require('../../fcs-crm/src/schemas/fcs_employee.json'),
	Director: require('../../fcs-crm/src/schemas/fcs_employee.json'),
	Management: require('../../fcs-crm/src/schemas/fcs_employee.json'),
	Shareholder: require('../../fcs-crm/src/schemas/fcs_shareholder.json'),
	'Service Provider': require('../../fcs-crm/src/schemas/fcs_service_providers.json'),
	UBOS: require('../../fcs-crm/src/schemas/fcs_ubos.json')
};

interface Props {
	store: CustomerStore;
	match?: any;
}

@inject(() => ({ store: customerStore as CustomerStore }))
@observer
class CustomerForms extends React.Component<Props & FormComponentProps, { redirectToUser: number }> {
	store: CustomerStore;

	constructor(props: Props & FormComponentProps) {
		super(props);
		this.store = props.store;
		this.state = {
			redirectToUser: 0
		};
	}

	componentDidMount() {
		this.props.store.fetchRequiredFiles();
		this.props.store.getOneCustomer(this.props.match.params.id);
	}

	@action
	setUserRole = () => {
		const { current_customer } = this.props.store;
		if (this.props.match.params.role && this.props.match.params.role != '') {
			current_customer.roles = this.props.match.params.role;
		}
	};

	saveForm = (values: any) => {
		var data = initialCustomer;
		data.id = parseInt(this.props.match.params.id) || 0;
		for (var key in values) {
			data[key] = values[key];
		}
		this.store.updateOrCreateCustomer(data, (res) => {
			console.log(data);
			if (data.id === 0 && res.id > 0) {
				this.setState({ redirectToUser: res.id });
			}
		});
	};

	render() {
		var header: string;
		this.setUserRole();
		const { current_customer } = this.store;
		const form = FORMS[current_customer.roles];

		if (current_customer.id != 0) {
			switch (current_customer.roles) {
				case 'Service Provider':
					header = current_customer.company_name;
					break;
				case 'Shareholder':
					header = current_customer.subscriber_name;
					break;
				default:
					header = current_customer.first_name + ' ' + current_customer.last_name;
					break;
			}
		} else {
			header = 'New ' + current_customer.roles;
		}
		const requiredFiles = this.store.required_files.get(current_customer.roles);
		if (this.state.redirectToUser > 0) {
			document.location.href = '/#/users/' + this.state.redirectToUser;
		}

		return (
			<div>
				<Row>
					<Col md={1}>
						<Link to="/users">
							<Button shape="circle" type="ghost" size="large">
								<Icon type="arrow-left" />
							</Button>
						</Link>
					</Col>
					<Col md={22}>
						<h1>{header}</h1>
					</Col>
				</Row>
				<Divider style={{ marginTop: 0 }} />
				<Row>
					<Col md={20} offset={2}>
						<Tabs defaultActiveKey="details" type="line" tabPosition="left" animated={false}>
							<TabPane tab="Details" key="details">
								<FormBuilder
									schema={form}
									id={current_customer.id}
									data={toJS(current_customer)}
									save={this.saveForm}
								/>
							</TabPane>
							{current_customer.id > 0 ? (
								<TabPane tab="Files" key="files">
									<FileList
										externalId={current_customer.id}
										subRole={current_customer.sub_role}
										module={'uf-' + current_customer.id.toString()}
										requiredfiles={requiredFiles || []}
									/>
								</TabPane>
							) : (
								<span />
							)}
						</Tabs>
					</Col>
				</Row>
			</div>
		);
	}
}

const CustomerDetails = Form.create<Props>()(CustomerForms);
export default CustomerDetails;
